@import "__fonts";
@import "__colors";
@import "__buttons";
@import "__mixins";
@import "__misc";

.dashboard-page {
  html {
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  :focus,
  input:focus,
  select:focus,
  textarea:focus,
  button:focus,
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  textarea {
    resize: none;
  }

  ul,
  li {
    list-style-type: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  body {
    font-family: $primary-font-normal;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    width: 100%;
    background: #f2f3f7;
    padding-bottom: 80px;
    // padding-top: 90px;
  }

  a {
    font-family: $primary-font-medium;
    color: #e80f6d;

    // text-decoration: none;
    &:hover {
      color: #e80f6d;
      // text-decoration: underline;
    }
  }

  .brand-logo-round {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #e0e2f0;
    background-color: #fff;
    box-sizing: border-box;
    margin: 0 0 32px 12px;
  }

  .profile-dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;

    .dropdown-content {
      display: none;
      position: absolute;
      right: 20px;
      background-color: #ffffff;
      border: 1px solid #e0e2f0;
      box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      border-radius: 12px;
      min-width: 200px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      margin-top: 1px;

      li {
        &:hover {
          background: #f2f3f7;
          -webkit-transition: all 0.35s;
          -moz-transition: all 0.35s;
          transition: all 0.35s;

          &.border-radius-top {
            border-radius: 12px 12px 0 0;
          }

          &.border-radius-bottom {
            border-radius: 0 0 12px 12px;
          }
        }
      }

      a {
        padding: 15px 22px;
        font-family: $primary-font-medium;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: $navbar-link-color;
        display: block;
        text-decoration: none;

        svg {
          position: relative;
          bottom: 2px;
          vertical-align: middle;
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }

        &:hover {
          color: $primary-color;
          -webkit-transition: all 0.35s;
          -moz-transition: all 0.35s;
          transition: all 0.35s;
          text-decoration: none;
        }
      }
    }

    &:hover {
      .dropdown-content {
        display: block;
      }
    }

    &:after {
      font-family: "Font Awesome 5 Free";
      vertical-align: middle;
      font-weight: 600;
      font-size: 8px;
      padding: 5px;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: "\f078";
      color: $bg-dark;
      position: relative;
      bottom: 0;
      bottom: 20px;
      left: 29px;
      background: #eff0f7;
      box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }

  // round image
  .round-image-32 {
    @include round-image(32);
  }

  .round-image-40 {
    @include round-image(40);
  }

  .round-image-48 {
    @include round-image(48);

    &.active {
      border-color: $purple;
    }
  }

  .round-image-50 {
    @include round-image(50);
  }

  .round-image-56 {
    @include round-image(56);
  }

  h1 {
    @include heading-one("bold");
  }

  // Left side navigation
  nav {
    margin: 40px;
    background-color: #ffffff;
    box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
    border-radius: 22px;

    &.navbar {
      &.fixed-left {
        padding: 16px 8px;
      }

      @media (max-width: 768px) {
        & {
          margin: 0;
          border-radius: 0;
        }
      }
    }

    .navbar-nav {
      li {
        // box-shadow: 0px 1px 0px #E0E2F0;
        &.active {
          background: rgba(232, 15, 109, 0.05);
          border-radius: 12px;

          svg {
            path {
              fill: $primary-color;
            }
          }
        }

        &:hover {
          background: rgba(232, 15, 109, 0.05);
          border-radius: 12px;
          -webkit-transition: all 0.35s;
          -moz-transition: all 0.35s;
          transition: all 0.35s;
          text-decoration: none;

          svg {
            path {
              fill: $primary-color;
            }
          }
        }
      }

      .nav-item {
        margin: 8px 0;

        .nav-link {
          padding: 15px 22px;
          font-family: $primary-font-medium;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: $navbar-link-color;

          svg {
            position: relative;
            bottom: 2px;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            margin-right: 16px;
          }

          &.active {
            color: $primary-color;
          }

          &:hover {
            color: $primary-color;
            -webkit-transition: all 0.35s;
            -moz-transition: all 0.35s;
            transition: all 0.35s;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    body {
      // margin-left: 190px;
    }
  }

  .navbar {
    right: auto;

    &.fixed-left {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1030;

      @media (min-width: 768px) {
        // bottom: 0;
        width: 310px;
        flex-flow: column nowrap;
        align-items: flex-start;

        .navbar-collapse {
          margin-top: 30px;
          flex-grow: 0;
          flex-direction: column;
          width: 100%;

          .navbar-nav {
            flex-direction: column;
            width: 100%;

            .nav-item {
              width: 100%;

              .dropdown-menu {
                top: 0;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .navbar-nav {
        .nav-item {
          .dropdown-toggle {
            &:after {
              border-top: 0.3em solid transparent;
              border-left: 0.3em solid;
              border-bottom: 0.3em solid transparent;
              border-right: none;
              vertical-align: baseline;
            }
          }

          .dropdown-menu {
            left: 100%;
          }
        }
      }
    }

    hr {
      box-shadow: 0px 1px 0px #e0e2f0;
    }

    @media (max-width: 768px) {
      button.navbar-toggler {
        // position: absolute;
        left: 18px;
        border: none;
        padding-left: 0;
        padding-right: 10px;
        top: 30%;
      }
    }
  }

  // end left side navigation

  // move to global
  .liked {
    svg {
      path {
        fill: $primary-color;
      }
    }
  }

  .reset-margin-left-5 {
    margin-left: 5px !important;
  }

  .card-min-height-235 {
    min-height: 235px !important;
  }

  .card-min-height-395 {
    min-height: 395px !important;
  }

  .card-min-height-380 {
    min-height: 380px !important;
  }

  .card-min-height-730 {
    min-height: 730px !important;
  }

  // move to global

  // dashboard
  // body container
  .dashboard-body-container {
    position: relative;
    top: 45px;
    padding-left: 390px;
    padding-right: 30px;

    .explore{
      display:flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 4px;

      .filter{
        border: 2px solid #E0E2F0;
        border-radius: 12px;
        padding:0.5rem;
        cursor:pointer
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-family: $primary-font-normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.4px;
      color: #4e4b66;
    }

    .card {
      border-radius: 22px;
      border: none;
      margin: 30px 15px 10px 0;
      min-height: 325px;
      height: auto;

      &.card-light {
        @include card-bg("light");
      }

      &.card-dark {
        @include card-bg("dark");
      }

      .card-title {
        font-family: $primary-font-normal;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.4px;

        &.with-bg {
          background: #eff0f7;
          border-radius: 15px;
          padding: 10px 18px;
        }
      }

      .notifications{
        max-height: 70vh;
        overflow-y:scroll;
      }

      .card-body {
        padding: 20px;

        .card-title-container {
          clear: both;

          span {
            border: 1px solid #e0e2f0;
            box-sizing: border-box;
            border-radius: 12px;
            padding: 10px 18px;
            a {
              color: $primary-color;
              &:hover{
                text-decoration:underline;
              }
            }
            .fa-chevron-right {
              font-size:10px;
              display:inline;
              color: $primary-color;
            }
          }
        }

        .notification{ 
          display:flex;
          justify-content:space-between;
          .notificationDescription{
              .bodyFirstPart{
                  color:#BA0C57;
              }
              &:hover{
                  cursor:pointer;
              }
          }
          p{
              font-size:15px;
              font-weight: 500;
              color: #6E7191;

          }
          .notification-title{
              font-family:$primary-font-medium;
              color: #14142B;
              font-size:16px;
              font-weight: 500;
          }
          .time{
              justify-content:center;
              text-align:center;
              font-size:10px;
              font-weight:bold;
              color: #6E7191;
              .fa-circle{
                  color:#E80F6D;
                  opacity:0.3;
              }

          }
          .fa-trash-alt{
              color:#d11a2a;
              cursor:pointer
          }
        }



        .recommended-profile {
          
          &:hover{
              background: rgba(242, 243, 247, 0.5);
              border-radius:5px;
          }
          .profile-details{
              &:hover{
                  cursor:pointer
              }
          }
          padding: 5px 0;
          margin-bottom: 10px;

          .round-image-48 {
            @include round-image(48);
          }

          p {
            margin-left: 22px;
            font-family: $primary-font-medium;
            line-height: 25px;
            color: #4e4b66;
            width: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            span {
              font-family: $primary-font-normal;
              display: block;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: #6e7191;
              i {
                font-size: 8px;
                color: #e0e2f0;
                margin: 0 10px;
                position: relative;
                top: -1px;
              }
            }
          }

          .recommendee {
            font-family: $primary-font-normal;
            padding: 7px 16px;
            background: #e0e2f0;
            border-radius: 39px;
            color: #14142b;
            font-size: 14px;
            line-height: 24px;
            margin: 5px 0 !important;
            &.pending {
              background: $pending-bg !important;
            }

            &.registered {
              background: $registered-bg !important;
            }

            &.rejected {
              background: $rejected-bg !important;
            }
          }
        }

        .childrenInvitation {
          margin-bottom: 20px;
          .round-image-48 {
              @include round-image(48);
          }
          p {
              margin-left: 22px;
              font-family: $primary-font-medium;
              line-height: 25px;
              color: #4E4B66;
              width: auto;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              span {
                  font-family: $primary-font-normal;
                  display: block;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.4px;
                  color: #6E7191;
                  i {
                      font-size: 8px;
                      color: #E0E2F0;
                      margin: 0 10px;
                      position: relative;
                      top: -1px;
                  }
              }
          }

          .recommendee {
            font-family: $primary-font-normal;
            padding: 7px 16px;
            background: #E0E2F0;
            border-radius: 39px;
            color: #14142B;
            font-size: 14px;
            line-height: 24px;
            margin: 5px 0 !important;
            &.pending {
                background: $pending-bg !important;
            }
            &.registered {
                background: $registered-bg !important;
            }
          }
        }

        
        .owl-carousel {
          position:relative;
          .favorite-item {
              &:hover{
                  .favouritesDetails{
                      visibility: visible;
                      opacity: 1;
                  }
              }
            
            img {
              border-radius: 22px;
              object-fit: cover;
              width: 100%;
              height: 200px;
              border: 1px solid #e0e2f0;
            }

            .favouritesDetails{
              visibility: hidden;
              opacity: 0;
              transition: visibility 0.6s, opacity 0.6s linear;
              background: rgba(0, 0, 0, 0.5);
              position:absolute;
              width:100%;
              bottom:0;
              border-radius: 0px 0px 22px 22px;
              padding-left:6px;
              display:flex;
              flex-direction: column;
              p{
                  color:#fff;
                  font-family:$primary-font-normal;
                  width: auto;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                  padding:0;
                  width: fit-content;
              }
            }
          }

          .owl-nav {
            text-align: right;
            margin-top: 15px;

            .owl-prev,
            .owl-next {
              border: 1px solid #e0e2f0;
              border-radius: 40px;
              width: 40px;
              height: 40px;
            }

            .owl-prev {
              span {
                display: none;
              }

              &:before {
                font-family: "Font Awesome 5 Free";
                vertical-align: middle;
                font-weight: 600;
                font-size: 14px;
                margin-right: 2px;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                content: "\f053";
                color: #a0a3bd;
              }
            }

            .owl-next {
              span {
                display: none;
              }

              &:before {
                font-family: "Font Awesome 5 Free";
                vertical-align: middle;
                font-weight: 600;
                font-size: 14px;
                margin-left: 2px;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                content: "\f054";
                color: #a0a3bd;
              }
            }
          }

          .owl-dots {
            position: absolute;
            bottom: 8px;

            .owl-dot {
              span {
                width: 16px;
              }

              &.active {
                span {
                  width: 32px;
                }
              }
            }
          }
        }
      }

      .card-footer {
        background-color: transparent;

        &.action-buttons {
          margin-top: 0px;

          button {
            height: 40px;
            border-radius: 40px;
          }
        }
      }

      .empty-card {
        padding: 30px 60px 40px 60px;

        p {
          font-family: $primary-font-normal;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.4px;
          color: #6e7191;
        }

        .icon-container {
          @include icon-container("light");
        }

        .icon-container-dark {
          @include icon-container("dark");
        }

        a {
          @include card-link("light");
        }

        a.dark {
          @include card-link("dark");
        }
        a.remove-default-style {
          border: none;
          border-radius: 0;
          margin-top: 0;
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }

  // users listing
  .users-listing {
    .card {
      border-radius: 22px;
      border: none;
      margin: 30px 15px 10px 0;
      // min-height: 380px;
      height: auto;
      padding-bottom: 16px;

      &:hover {
        box-shadow: 0px 8px 16px 0px rgba(17, 17, 17, 0.04);
        cursor: pointer;
      }

      .card-body {
        padding: 0;

        .list-img-container {
          margin-bottom: 16px;

          img {
            border-radius: 12px 12px 0 0;
            object-fit: cover;
            width: 100%;
            height: 280px;
          }
        }

        .list-profile-details {
          font-family: $primary-font-medium;
          font-size: 16px;
          line-height: 28px;
          color: #4e4b66;
          padding: 0 16px;

          p{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          span {
            display: block;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #6e7191;

            i {
              font-size: 8px;
              color: #e0e2f0;
              margin: 0 10px;
              position: relative;
              top: -1px;
            }
          }

          .like-it {
            &:hover {
              svg {
                path {
                  fill: $primary-color;
                }
              }
            }
          }
        }
      }
    }
  }

  .single-explore-profile {
    .round-image-120 {
      @include round-image(120);
      margin-bottom: 15px;
    }

    .profile-like-it {
      background: #f2f3f7;
      border-radius: 50px;
      height: 50px;
      width: 50px;

      svg {
        margin: 14px 13.5px;
        width: 24px;
        height: 24px;
      }

      // &:hover {
      //     svg {
      //         path {
      //             fill: $primary-color;
      //             cursor: pointer;
      //         }
      //     }
      // }
    }

    .back-to-network {
      cursor: pointer;

      &:before {
        font-family: "Font Awesome 5 Free";
        vertical-align: middle;
        font-weight: 600;
        font-size: 12px;
        padding: 15px 18px;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f053";
        color: $bg-dark;
        background: #e0e2f0;
        box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
        border-radius: 50%;
        width: 54px;
        height: 54px;
      }
    }

    .profile-img-with-text {
      p {
        font-family: $primary-font-normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        padding: 0;
        margin: 0;
      }

      span {
        font-family: $primary-font-normal;
        display: block;
        padding: 0;
        margin: 0;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.4px;
        color: #6e7191;

        i {
          font-size: 8px;
          color: #e0e2f0;
          margin: 0 10px;
          position: relative;
          top: -1px;
        }
      }

      button {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        line-height: 23px;
        height: 45px;
      }
    }

    .user-parents {
      .round-image-56 {
        @include round-image(56);
      }

      .card {
        min-height: 60px;
        margin-top: 16px;
      }

      p {
        font-family: $primary-font-normal;
        font-weight: 500;
        display: inline;
        font-size: 15px;
        color: $bg-dark;
        line-height: 26px;

        span {
          font-family: $primary-font-normal;
          display: block;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.4px;
          color: #6e7191;
        }
      }
    }

    .content-wrapper {
      margin-top: 30px;

      .user-avatar {
        background: #ffffff;
        border-radius: 22px;
        padding: 40px;
        margin-bottom: 30px;
      }

      .user-hobbies,
      .user-location,
      .user-bio,
      .user-pictures {
        margin-bottom: 36px;

        & > p {
          margin-bottom: 20px;
        }
      }

      .user-hobbies {
        & > div {
          & > span {
            background: #e0e2f0;
            border-radius: 32px;
            font-size: 14px;
            color: #4e4b66;
            line-height: 28px;
            display: inline-block;
            margin-right: 16px;
            padding: 4px 12px;
            margin-bottom: 20px;
          }
        }
      }

      .user-location {
        & > div {
          background: #e0e2f0;
          border-radius: 32px;
          font-size: 14px;
          color: #4e4b66;
          line-height: 28px;
          display: inline-block;
          margin-right: 16px;
          padding: 4px 12px;
        }

        svg {
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }

      .user-bio {
        & > div {
          & > p {
            color: #6e7191;
          }
        }
      }

      .user-pictures {
        img {
          cursor: pointer;
        }
      }
    }

    .user-pictures {
      .list-img-container {
        margin-bottom: 20px;

        img {
          border-radius: 18px;
          object-fit: cover;
          width: 100%;
          height: 300px;
          box-shadow: 0px 6.51515px 13.0303px rgba(17, 17, 17, 0.04);
        }
      }
    }
  }

  .user-profile-modal {
    .action-buttons {
      button {
        height: 40px;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .modal-content {
      .form-floating {
        label {
          padding-left: 24px;
        }
      }

      .modal-body {
        
        .profileImg {
          margin-bottom: 16px;

          svg {
            position: absolute;
            top: 109px;
            left: 100px;
            width: 26px;
            height: 26px;
            padding: 4px;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #e0e2f0;
            box-sizing: border-box;
            // box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
            cursor: pointer;
          }
        }

        .profileName {
          margin-bottom: 20px;
        }

        .profileAgeGender {
          margin-bottom: 24px;
        }

        .profilePictures {
          .image-wrapper {
            position: relative;
            img {
              border-radius: 15px;
              height: 160px;
              width: 180px;
              object-fit: cover;
            }
            span {
              position: absolute;
              background: #ffffff;
              width: 22px;
              height: 22px;
              border: 1px solid #e0e2f0;
              box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
              border-radius: 50%;
              cursor: pointer;
              color: #e80f6d;
              font-weight: bold;
              top: -3px;
              right: 8px;
              text-align: center;
              svg {
                width: 12px;
                height: 12px;
                position: relative;
                bottom: 5px;
                left: .8px;
              }

              &:hover {
                background: #e80f6d;
                color: #ffffff;
                svg {
                  path {
                      stroke: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }

    .edit-preferences-trigger {
      margin: 25px 0;

      a {
        padding: 28px 0;
        font-size: 16px;
        color: $primary-color;
        text-decoration: none;
        font-family: $primary-font-medium;
        box-shadow: 0px 1px 0px #e0e2f0, 0px -1px 0px #e0e2f0;

        &:hover {
          background: #fbfbfb;

          svg {
            path {
              stroke: #14142b;
            }
          }
        }
      }

      svg {
        display: inline-block;
        width: 24px;
        height: 24px;
        padding: 5px;
        border-radius: 50%;
        border: #e0e2f0 solid 2px;
        position: relative;
      }
    }
  }

  .chat-container {
    .back-to-chats {
      cursor: pointer;

      &:before {
        font-family: "Font Awesome 5 Free";
        vertical-align: middle;
        font-weight: 600;
        font-size: 12px;
        padding: 15px 18px;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f053";
        color: $bg-dark;
        background: #e0e2f0;
        box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
        border-radius: 50%;
        width: 54px;
        height: 54px;
      }
    }
    .card {
      max-height: 770px;
      border: 1px solid #e0e2f0;

     

      p.title {
        padding-left: 20px;
        padding-right: 20px;
        font-family: $primary-font-medium;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: $bg-dark;
      }

      .icon-container-no-bg {
        svg {
          margin-top: 150px;
        }
      }

      .chat-list-container {
        margin: 25px 0;
        padding-left: 20px;
        padding-right: 20px;

        .user-search-input {
          display:flex;
          align-items: center;
          background: #eff0f7;
          border-radius: 12px;
          border: transparent;

          input {
            border: transparent;
            padding: 14px 20px 14px 0px;
            background: #eff0f7;
            border-radius: 12px;
          }

          .icon-search {
            position: relative;
            padding: 13px 10px 13px 20px;
            pointer-events: none;

            svg {
              path {
                stroke: #a0a3bd;
              }
            }
          }

          /* align icon */
          .left-addon .icon-search {
            left: 0px;
          }

          .right-addon .icon-search {
            right: 0px;
          }

          /* add padding  */
          .left-addon input {
            padding-left: 30px;
          }

          .right-addon input {
            padding-right: 30px;
          }
        }
      }

      .chat-list-scroll {
        max-height: 590px;
        .user-list-container {
          padding: 14px 20px;
          cursor: pointer;

          &.active {
            background: rgba(239, 240, 247, 0.5);
            box-shadow: inset 2px 0px 0px #e80f6d;
          }

          &:hover {
            background-color: rgba(242, 243, 247, 0.5);
          }

          .user-single-list {
            p {
              position: relative;
              top: 4px;
              display: inline;
              padding-left: 16px;
              font-family: $primary-font-medium;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: $bg-dark;

              &.new {
                top: 12px;
              }

              span {
                font-family: $primary-font-normal;
                display: block;
                margin-left: 64px;
                font-size: 14px !important;
                line-height: 24px;
                letter-spacing: 0.4px;
                color: #6e7191;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }

            .status-time-ref {
              position: relative;
              top: 3px;
              display: inline-block;
              padding-top: 6px;
              font-family: $primary-font-bold;
              font-size: 12px;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: #6e7191;

              .chat-count-inner {
                font-size: 11px;
                display: block;
                width: 24px;
                height: 24px;
                color: #ba0c57;
                background: rgba(186, 12, 87, 0.1);
                border-radius: 40px;
              }
            }

            .recommended-by {
              display: inline-block;
              font-family: $primary-font-normal;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: #6e7191;

              img {
                margin-right: 15px;
              }
            }
          }
        } 
      }

      .card-body {
        padding: 20px 0 !important;
        // overflow-y: scroll;
        // overflow-x: hidden;

        .message-body-container {
          padding: 40px 20px;

          .message-thread {
            font-family: $primary-font-normal;

            .match-request,
            .match-request-responder {
              position: absolute;
              margin-left: -50px;
              top: 84px;
              z-index: 999;
            }

            .match-request {
              left: 45%;
              padding: 6px 12px;
              background: #ffffff;
              border: 1px solid #e0e2f0;
              box-sizing: border-box;
              box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
              border-radius: 48px;
              font-size: 12px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #e80f6d;

              .request-btn {
                margin-left: 6px;
                display: inline;
                border: none;
                width: 35px;
                height: 35px;
                background: #e80f6d;
                border-radius: 35px;
                padding: 3px;

                svg {
                  padding-right: 3px;
                }
              }
            }

            .match-request-responder {
              left: 39%;
              background: #ffffff;
              border: 1px solid #e0e2f0;
              box-sizing: border-box;
              border-radius: 22px;
              padding: 12px 0 12px 16px;

              .match-profile-container,
              .children {
                display: inline-block;
                vertical-align: top;
                font-size: 12px;
                letter-spacing: 0.4px;
                color: #6e7191;

                figure {
                  margin: 0;
                  padding: 0;
                }

                .caption {
                  margin-top: 3px;
                  text-align: center;
                  min-width: 45px;
                }

                .divider {
                  position: relative;
                  top: 14px;
                  margin-right: 4px;
                }
              }

              .match-action-btn {
                top: 11px;
                position: relative;
                display: inline;
                margin-left: 20px;
                background: #f2f3f7;
                border-radius: 22px;
                padding: 32px 16px;

                .request-btn {
                  display: inline;
                  border: none;
                  width: 40px;
                  height: 40px;
                  background: $white;
                  border-radius: 40px;
                  padding: 6px;

                  svg {
                    padding-left: 1px;
                  }
                  &:hover {
                    border: 2px solid #a19faf;
                  }
                }
              }
            }

            p {
              font-size: 14px;
              color: #4e4b66;
              margin: 8px 0;
              padding: 12px 20px;
              line-height: 22px;
              letter-spacing: 0.4px;
            }

            .image {
              width: -webkit-fill-available;
              object-fit: cover;
              height: auto;
              display: block;
              border-radius: 22px;
            }

            .chat-time {
              font-family: $primary-font-medium;
              display: block;
              font-size: 10px;
              line-height: 24px;
              color: #6e7191;
              letter-spacing: 0.4px;
              padding-left: 3px;
            }

            .inbound-message-content {
              p {
                width: auto;
                height: auto;
                background: #ffffff;
                border: 1px solid #e0e2f0;
                border-radius: 12px 0px 12px 12px;
                max-width: 47%;

                &.image-box {
                  border-color: transparent;
                }
              }
            }

            .outbound-message-content {
              position: relative;
              left: 50%;

              p {
                width: auto;
                height: auto;
                background: #eff0f7;
                border-radius: 0px 12px 12px 12px;
                max-width: 47%;

                &.image-box {
                  background: transparent;
                }
              }

              .chat-time {
                position: relative;
                width: 47%;
                text-align: right;
              }
            }
          }
        }
      }

      .card-header {
        background-color: transparent;
        padding: 12px 20px;

        p {
          font-family: $primary-font-medium;
          display: inline;
          margin-left: 10px;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: $bg-dark;

          span {
            font-family: $primary-font-normal;
            display: block;
            margin-left: 58px;
            font-size: 14px !important;
            line-height: 12px;
            letter-spacing: 0.4px;
            color: #6e7191;

            i {
              font-size: 8px;
              color: #e0e2f0;
              margin: 0 10px;
              position: relative;
              top: -1px;
            }
          }

          .messageHeader{
            font-size:12px !important;
            margin-top:11px;
            padding:4px;
            text-align:center;
            width : 150px;
            font-weight:bold;
            display:block;
            border-radius:50px;
            color:cadetblue;
            border:1px solid aquamarine
          }
        }

        .chat-top-actions {
          padding-top: 4px;

          li {
            display: inline-block;
            height: 40px;
            width: 40px;
            padding: 8px;
            vertical-align: baseline;
            border: 2px solid #e0e2f0;
            box-sizing: border-box;
            border-radius: 12px;

            &:hover.search {
              border: 2px solid $bg-dark;
              cursor: pointer;

              svg {
                path {
                  stroke: $bg-dark;
                }
              }
            }
          }
        }
      }

      .card-footer {
        padding: 20px;
        border-top: none;

        .chat-component-container {
          align-items: center;
          padding: 12px;
          background: #ffffff;
          border: 1px solid #e0e2f0;
          box-sizing: border-box;
          box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
          border-radius: 80px;

          .chat-input {
            background: #eff0f7;
            border-radius: 56px;
            height: 66px;
            border: none;
            padding: 10px 20px;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.4px;

            .content-editable {
              padding-top: 11px;
              padding-bottom: 11px;
              height: 46px;
              overflow: scroll;
              color: $navbar-link-color;
              -ms-overflow-style: none;
              /* IE and Edge */
              scrollbar-width: none;

              /* Firefox */
              &[aria-label]:empty:before {
                content: attr(aria-label);
                color: #a0a3bd;
              }

              &[aria-label]:empty:focus:before {
                content: "";
              }

              /* Hide scrollbar for Chrome, Safari and Opera */
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }

          .action-icon-container {
            padding: 19px 0;

            li {
              list-style-type: none;
              display: inline;
              padding: 0 6px;

              &:hover.attachment {
                cursor: pointer;

                svg {
                  path {
                    stroke: $primary-color;
                  }
                }
              }

              &:hover.emoji {
                cursor: pointer;

                svg {
                  path {
                    stroke: rgb(201, 138, 22);
                  }
                }
              }

              &:hover.image {
                cursor: pointer;

                svg {
                  path {
                    stroke: rgb(20, 148, 148);
                  }
                }
              }
            }
          }
        }

        .typing-awareness {
          margin-top: 10px;
          text-align: center;
          color: 4e4b66;
          font-family: $primary-font-normal;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }

  // modals
  .modal {
    font-family: $primary-font-normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #6e7191;
    max-height:90%;  
    

    .modal-dialog {
      min-width: 650px;
      
      @media (max-width: 765px){
        min-width: 100%;
        margin:0rem;

        padding:0.5rem;
      }
    }

    .modal-header {
      border-bottom: none;
      

      h5 {
        font-family: $primary-font-medium;
        font-size: 18px;
        line-height: 24px;
        color: #14142b;
      }

      .btn-close {
        font-size: 11px;
        fill: #6e7191;
      }
    }


    .modal-content {
      padding: 10px;
      background: #ffffff;
      
      // box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
      border-radius: 22px;

      #height-range {
        margin: 30px 10px 40px 10px;
        z-index:0;
    }

    .noUi-target {
        background: #d9dbe9;
        height: 12px;
        border-radius: 40px;
        border: none;
    }

    .noUi-connect {
        background: #e80f6d;
        border-radius: 40px;
        z-index: -10;
    }

    .noUi-connects {
        border-radius: 40px;
    }

    

    .noUi-horizontal .noUi-handle {
        border-radius: 50px;
        width: 25px;
        height: 25px;
        right: -17px;
        top: -7px;
        background: #f7f7fc;
        border: 2px solid #e80f6d;
        box-sizing: border-box;
        cursor: pointer;
    }

    .noUi-handle:after,
    .noUi-handle:before {
        display: none;
    }

    .noUi-marker-horizontal.noUi-marker-large {
        height: 0;
    }

    .noUi-horizontal .noUi-tooltip {
        background: #fadce9;
        width: 60px;
        border-radius: 16px;
        padding: 6px 16px;
        margin-bottom: 6px;
        border: none;
        font-family: $secondary-font-normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: #ba0c57;
    }

    .noUi-pips-horizontal {
        padding: 4px 0;
        font-family: $secondary-font-normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: #14142b;
    }

      select {
        padding-bottom: 0 !important;
      }

      input,
      select,
      textarea {
        background: #eff0f7;
        border: none;
        color: #6e7191;
        border-radius: 16px;
        padding-left:24px;
        padding-right:24px;
      }

      .action-buttons {
        margin-top: 0px;

        button {
          border-radius: 40px;
          height: 40px;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.75px;
        }
      }

      .recommended-profile {
        .grayed{
          opacity:0.6;
        }
        &:hover{
            background: rgba(242, 243, 247, 0.5);
            border-radius:5px;
        }
        .profile-details{
            &:hover{
                cursor:pointer
            }
        }
        margin-bottom: 20px;
        .round-image-48 {
            @include round-image(48);
        }
        p {
            margin-left: 22px;
            font-family: $primary-font-medium;
            line-height: 25px;
            color: #4E4B66;
            width: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            span {
                font-family: $primary-font-normal;
                display: block;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.4px;
                color: #6E7191;
                i {
                    font-size: 8px;
                    color: #E0E2F0;
                    margin: 0 10px;
                    position: relative;
                    top: -1px;
                }
            }
        }
        .recommendee {
            font-family: $primary-font-normal;
            padding: 7px 16px;
            background: #E0E2F0;
            border-radius: 39px;
            color: #14142B;
            font-size: 14px;
            line-height: 24px;
            margin: 5px 0 !important;
            &.pending {
                background: $pending-bg !important;
            }
            &.registered {
                background: $registered-bg !important;
            }
        }
        .fa-trash-alt{
            color:#d11a2a;
            cursor:pointer
        }
      }

      .childrenInvitation {
        margin-bottom: 20px;
        .round-image-48 {
            @include round-image(48);
        }
        p {
            margin-left: 22px;
            font-family: $primary-font-medium;
            line-height: 25px;
            color: #4E4B66;
            width: auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            span {
                font-family: $primary-font-normal;
                display: block;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.4px;
                color: #6E7191;
                i {
                    font-size: 8px;
                    color: #E0E2F0;
                    margin: 0 10px;
                    position: relative;
                    top: -1px;
                }
            }
        }
        .fa-trash-alt{
            color:#d11a2a;
            cursor:Pointer
        }
        .fa-redo{
            cursor:Pointer;

        }
        .spin-redo{
            animation: spin 0.8s linear infinite;
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
      }
      .grayed{
          opacity:0.6;
      }
      .recommendee {
          font-family: $primary-font-normal;
          padding: 7px 16px;
          background: #E0E2F0;
          border-radius: 39px;
          color: #14142B;
          font-size: 14px;
          line-height: 24px;
          margin: 5px 0 !important;
          &.pending {
              background: $pending-bg !important;
          }
          &.registered {
              background: $registered-bg !important;
          }
      }
    }

      .recommend-popup {
        background: #ffffff;
        border: 1px solid #e0e2f0;
        box-sizing: border-box;
        border-radius: 96px;
        padding: 15px 6px;
        margin: 0 0 20px 0;

        p {
          font-family: $primary-font-medium;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.4px;
          color: $bg-dark;

          span {
            display: block;
            font-family: $primary-font-normal;
            font-size: 14px !important;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #6e7191;

            i {
              font-size: 8px;
              color: #e0e2f0;
              margin: 0 10px;
              position: relative;
              top: -1px;
            }
          }
        }

        /* Hide the browser's default radio button */
        .custom-radio-btn {
          display: block;
          position: relative;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* When the radio button is checked */
            &:checked ~ .check {
              background-color: $primary-color;
              border: 2px solid $primary-color;
              -webkit-transition: all 0.25s linear;
            }

            /* Show the indicator (dot/circle) when checked */
            &:checked ~ .check:after {
              display: block;
            }

            &:hover ~ .check {
              background-color: $primary-color;
            }
          }

          .check {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background-color: $white;
            border: 2px solid #e0e2f0;
            transition: border 0.25s linear;
            -webkit-transition: border 0.25s linear;
            border-radius: 50%;

            /* Create the indicator (the dot/circle - hidden when not checked) */
            &:after {
              content: "";
              position: absolute;
              display: none;
              top: 9px;
              left: 9px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: $white;
            }
          }
        }
      }
    }

    .modal-footer {
      border-top: none;
    }
  }
}
