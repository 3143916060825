/* .imageCard {
  position: relative;
} */

.favoriteIcon {
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.favoriteSvg {
  color: #e80f6d;
  font-size: larger;
  text-shadow: 2px 2px 2px #f55399;
  transition: 1s ease-in-out;
  animation: pulse 4s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

/* .favoriteSvg:hover {
  transform: scale(1.3);
} */

/* .imageCard {
  width: 110px !important;
} */
.imgRounded {
  position: absolute;
  top: 155px;
  right: 12px;
  width: 32px !important;
  height: 32px !important;
}
