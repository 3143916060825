@import '__fonts';
@import '__colors';
@import '__buttons';
@import '__mixins';
@import '__login';
@import '__misc';

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 576px) {
    // onboarding and external pages
    body {
        background-image: none;
    }
    .logo-container {
        margin: 10px 10px;
    }
    
    // Onboarding process for child account
    .onboarding {
        padding-top: 30px;
        margin: 0 15px;
    
        .cta-option-group {
            padding: 0 10px;
        }
    
        // height range
        .noUi-pips-horizontal {
            left: 17px;
            width: 90%;
        }
    
        // tag cloud
        .tc-tag {
            padding: 12px 20px;
            font-size: 12px;
        }
    }

    // login page - login.scss
    .aunty-signup-login {
        .promotional-left-panel {
            display: none;
        }
        .form-content-container {
            .login-button-options {
    
                li {
                    a {
                        padding: 17px 25px;
                    }
                }
            }
        }
    }

    // Left side navigation
    .brand-logo-round {
        margin: 0 !important;
    }
    nav {
        &.navbar {
            &.fixed-left {
                // padding-bottom: 0 !important;
            }
        }
    }
    .navbar {
        right: auto;

        &.fixed-left {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1030;

            .navbar-nav {
                .nav-item {
                    .dropdown-toggle {
                        &:after {
                            border-top: 0.3em solid transparent;
                            border-left: 0.3em solid;
                            border-bottom: 0.3em solid transparent;
                            border-right: none;
                            vertical-align: baseline;
                        }
                    }
                    .dropdown-menu {
                        left: 100%;
                    }
                }
            }
        }
    }
    // end left side navigation

    // dashboard
    .dashboard-body-container {
        padding: 0 !important;
        margin-top: 45px;

        .card {
            margin: 30px 0 10px 0 !important;
            .card-body {
                padding: 20px;
                .card-title-container {
                    clear: both;
                    span {
                        border: 1px solid #E0E2F0;
                        box-sizing: border-box;
                        border-radius: 12px;
                        padding: 10px 18px;
                        a {
                            color: $primary-color;
                        }
                    }
                }
                .recommended-profile {
                    margin-bottom: 20px;
                    .round-image-48 {
                        @include round-image(48);
                    }
                    p {
                        margin-left: 22px;
                        font-family: $primary-font-medium;
                        line-height: 25px;
                        color: #4E4B66;
                        span {
                            font-family: $primary-font-normal;
                            display: block;
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: 0.4px;
                            color: #6E7191;
                            i {
                                font-size: 8px;
                                color: #E0E2F0;
                                margin: 0 10px;
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                    .recommendee {
                        font-family: $primary-font-normal;
                        padding: 7px 16px;
                        background: #E0E2F0;
                        border-radius: 39px;
                        color: #14142B;
                        font-size: 14px;
                        line-height: 24px;
                        margin: 5px 0 !important;
                        &.pending {
                            background: $pending-bg !important;
                        }
                        &.registered {
                            background: $registered-bg !important;
                        }
                    }

                }
                .owl-carousel {
                    .favorite-item {
                        img {
                            border-radius: 22px;
                            object-fit: cover;
                            width: 100%;
                            height: 200px;
                            border: 1px solid #E0E2F0;
                        }
                    }
                    .owl-nav {
                        text-align: right;
                        margin-top: 15px;
                        .owl-prev, .owl-next {
                            border: 1px solid #E0E2F0;
                            border-radius: 40px;
                            width: 40px;
                            height: 40px;
                        }
                        .owl-prev {
                            span {
                                display: none;
                            }
                            &:before {
                                font-family: "Font Awesome 5 Free";
                                vertical-align: middle;
                                font-weight: 600;
                                font-size: 14px;
                                margin-right: 2px;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                content: "\f053";
                                color: #A0A3BD;
                            }
                        }
                        .owl-next {
                            span {
                                display: none;
                            }
                            &:before {
                                font-family: "Font Awesome 5 Free";
                                vertical-align: middle;
                                font-weight: 600;
                                font-size: 14px;
                                margin-left: 2px;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                content: "\f054";
                                color: #A0A3BD;
                            }
                        }
                    }
                    .owl-dots {
                        position: absolute;
                        bottom: 8px;
                        .owl-dot { 
                            span {
                                width: 16px;
                            }
                            &.active {
                                span {
                                    width: 32px;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    // onboarding and external pages
    body {
        background-image: none;
    }
    
    // Onboarding process for child account
    .onboarding {
        padding-top: 70px;
        margin: 0 15px;
    
        .cta-option-group {
            padding: 0 10px;
        }
    
        // height range
        .noUi-pips-horizontal {
            left: 12px;
            width: 95%;
        }
    }

    // login page - login.scss
    .aunty-signup-login {
        .promotional-left-panel {
            display: none;
        }
        .form-content-container {
            .login-button-options {
    
                li {
                    a {
                        padding: 17px 40px;
                    }
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    // onboarding and external pages
    body {
        background-image: initial;
    }
    
    // Onboarding process for child account
    .onboarding {
        padding-top: 50px;
        margin: 0 15px;

        .heading-group {
            margin-bottom: 20px;
        }
    
        // height range
        .noUi-pips-horizontal {
            left: 0;
            width: 100%;
        }
    }

    // login page - login.scss
    .aunty-signup-login {
        .promotional-left-panel {
            display: none;
        }
        .form-content-container {
            .login-button-options {
    
                li {
                    a {
                        padding: 17px 60px;
                    }
                }
            }
        }
    }

    // Left side navigation
    .brand-logo-round {
        margin: 0 !important;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    // onboarding and external pages
    .onboarding {
        padding-top: 50px;
    }

    // login page - login.scss
    .aunty-signup-login {
        .promotional-left-panel {
            display: block;
        }
        .form-content-container {
            .login-button-options {
    
                li {
                    a {
                        padding: 17px 70px;
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    // onboarding and external pages
    .onboarding {
        padding-top: 120px;
    }

    // login page - login.scss
    .aunty-signup-login {
        .promotional-left-panel {
            display: block;
        }
        .form-content-container {
            .login-button-options {
    
                li {
                    a {
                        padding: 17px 100px;
                    }
                }
            }
        }
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {  }


@media (max-width:330px){
    .network-item{
        width:100%;
    }
}