.loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3px solid whitesmoke;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.loadersm {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid whitesmoke;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
}

.loadersm1 {
  border: 2px solid rgba(232, 15, 109, 0.3);
  border-radius: 50%;
  border-top: 2px solid rgb(232, 15, 109);
  width: 35px;
  height: 35px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
}

.loadersm2 {
  border: 2px solid rgba(232, 15, 109, 0.3);
  border-radius: 50%;
  border-top: 2px solid rgb(232, 15, 109);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
