.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker {
border-radius: 16px;
overflow: hidden;
border-color: #eff0f7;
font-family: inherit;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
margin-top: 0;
color: white;
font-weight: bold;
font-size: 0.944rem;
margin-bottom: 10px;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
background-color: white;
}
.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
color: #777a98;
padding: 3px;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
background-color: #d7cef9;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
color: white;
display: inline-block;
width: 1.7rem;
line-height: 1.7rem;
text-align: center;
margin: 0.166rem;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
cursor: pointer;
color: #a0a3bd;
border-radius: 16px;
}
.react-datepicker__header {
text-align: center;
background-color: #e80e6d;
border-bottom: none;
padding: 8px 0;
position: relative;
color: white;
}
.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
border-color: white;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
background-color: #824dff;
color: #fff;
}
.react-datepicker_day,
.react-datepickermonth-text,
.react-datepickerquarter-text,
.react-datepicker_year-text {
cursor: pointer;
color: #a2a5be;
padding: px;
}
.react-datepicker_day:hover,
.react-datepickermonth-text:hover,
.react-datepickerquarter-text:hover,
.react-datepicker_year-text:hover {
border-radius: 16px;
background-color: #eff0f7;
color: #aaacc4;
}

.ct-toast-success {
  font-family: $primary-font-normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: .4;
  color: #FFFFFF;
  background: #6EC05F;
}

// .ct-toast {
//     font-family: inherit;
//     border-radius: 4px;
//     min-width: 40vw;
//     border-left: none !important;
//     background: linear-gradient(114.44deg, #c146ff 0%, #8c4bff 100%);
//     svg {
//       display: none;
//     }
//   }
  
//   .ct-text {
//     font-size: 16px;
//     color: white;
//     font-weight: bold;
//     font-family: inherit;
//   }