// color declaration
$primary-color : #E80F6D;
$secondary-color : #59c203;
$section-color : #f1f9f5;
$white : #FFFFFF;
$black : #000000;
$purple : #5F2EEA;
$navbar-link-color : #4E4B66;
$bg-dark : #14142B;
$bg-dark-purple : #1E1B5B;
$pending-bg : #faebd7;
$registered-bg : darkseagreen;
$rejected-bg:#e80f45; 
$color_1: #6E7191;
$color_2: #A0A3BD;
$color_3: #F7F7FC;
$color_4: #EFF0F6;
$color_5: #E0E2F0;
$font-family_1: 'DM Sans',
sans-serif;