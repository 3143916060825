.action-buttons {
    margin-top: 25px;
    button {
        border-radius: 50px;
        height: 50px;
        width: 50px;
        color: $primary-color;
        background-color: transparent;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.75px;
        border: 1px solid $primary-color;
        &:hover {
            color: $white;
            background-color: $primary-color;
            transition: all 1.0s ease;
        }
        &.fa-previous {
            &:before {
                font-family: "Font Awesome 5 Free";
                content: "\f053";
                display: inline-block;
                padding-right: 3px;
                vertical-align: middle;
                font-weight: 600;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
        }
        &.fa-next {
            &:before {
                font-family: "Font Awesome 5 Free";
                content: "\f054";
                display: inline-block;
                padding-left: 3px;
                vertical-align: middle;
                font-weight: 600;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
            }
        }
        &.btn.disabled, &.btn:disabled {
            pointer-events: none;
            opacity: .65;
            background-color: $primary-color;
            color: $white;
            border-color: transparent;
        }
        &.solid-btn {
            background: $primary-color;
            border-radius: 40px;
            font-family: $primary-font-normal;
            width: auto;
            padding-left: 25px;
            padding-right: 25px;
            color: $white;
            &:before {
                content: none;
            }
            &:hover {
                color: $white;
                background-color: #ba0c57;
                transition: all 1.0s ease;
            }
        }
        &.transparent-btn {
            background: $white;
            border-radius: 40px;
            font-family: $primary-font-normal;
            width: auto;
            padding-left: 25px;
            padding-right: 25px;
            color: $primary-color;
            &:before {
                content: none;
            }
            &:hover {
                color: $white;
                background-color: $primary-color;
                transition: all 1.0s ease;
            }
        }
        &.transparent-gray {
            background: $white;
            border-radius: 40px;
            border: 2px solid #D9DBE9;
            font-family: $primary-font-normal;
            width: auto;
            padding-left: 25px;
            padding-right: 25px;
            color: $primary-color;
            &:before {
                content: none;
            }
            &:hover {
                color: $black;
                background-color: #D9DBE9;
                transition: all 1.0s ease;
            }
        }
    }

}