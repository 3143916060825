@import "__fonts";
@import "__colors";
@import "__buttons";
@import "__mixins";
@import "__login";
@import "__misc";

html {
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
}

*,
:after,
:before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

:focus,
input:focus,
select:focus,
textarea:focus,
button:focus,
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

textarea {
    resize: none;
}

ul,
li {
    list-style-type: none;
}

body {
    background-color: $white;
    font-family: $primary-font-normal;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale !important;
    -webkit-font-smoothing: antialiased !important;
    line-height: 18px;
    width: 100%;
    background-image: url("../images/bg_image.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

a {
    font-family: $primary-font-medium;
    color: #e80f6d;
    text-decoration: none;

    &:hover {
        color: #e80f6d;
        text-decoration: underline;
    }
}

p {
    font-family: $primary-font-normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.4px;
    color: #6e7191;
}

h1 {
    @include heading-one("bold");
}

.logo-container {
    margin: 10px 60px;
    border: 1px solid #e0e2f0;
    background-color: $white;
    box-sizing: border-box;
    border-radius: 50%;

    img {
        width: auto;
        height: 50px;
    }
}

// Onboarding process for child account
.onboarding {
    padding-top: 120px;

    .checkmark {
        margin-bottom: 20px;
        width: 90px;
        height: auto;
    }
    

    .text-container {
        .dashboardReady{
            animation:fadeIn ease 2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
    
            @keyframes fadeIn {
                0%{
                    opacity:0;
                }
                100%{
                    opacity:1
                }
            }
        }
    
    
        .dashboardSpiral{
            display:flex;
            justify-content: center;
        }

        p {
            margin: 0;
            padding: 0;

            &.small {
                @include common-text-primary;
                font-weight: 500;
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    .heading-group {
        margin-bottom: 35px;

        span {
            width: auto;
            height: 45px;
            margin-bottom: 10px;

            &.step-1 {
                &:before {
                    content: "👋";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-2 {
                &:before {
                    content: "💍";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-3 {
                &:before {
                    content: "🎉";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-4 {
                &:before {
                    content: "🗺️";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-5 {
                &:before {
                    content: "😀";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-6 {
                &:before {
                    content: "📏";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-7 {
                &:before {
                    content: "💃";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-8 {
                &:before {
                    content: "😇";
                    left: 32px;
                    font-size: 18px;
                }
            }

            &.step-9 {
                &:before {
                    content: "✉️";
                    left: 32px;
                    font-size: 18px;
                }
            }
        }

        h1 {
            @include heading-one("normal");
        }
    }

    .cta-option-group {
        button {
            margin: 8px 0;
            padding: 13px 0;
            border-radius: 40px;
            border: 2px solid #d9dbe9;
            box-sizing: border-box;
            background-color: transparent;
            font-family: $secondary-font-normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.75px;
            color: rgb(232, 15, 109);
            transition: all 0.3s;
            position: relative;
            overflow: hidden;
            z-index: 1;

            &:hover {
                border: 2px solid #fcedf3;

                &:before {
                    width: 100%;
                }
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -2;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                height: 100%;
                background-color: darken(#fcedf3, 0.1%);
                transition: all 0.3s;
                z-index: -1;
            }
        }
    }

    .input-container {
        .terms-privacy {
            font-family: $primary-font-normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #6e7191;
        }

        input {
            @include input;
            border-radius: 16px;
            padding: 6px 18px;
            height: 54px;
        }

        select {
            @include input;
            border-radius: 16px;
            padding: 6px 18px;
            height: 54px;
        }

        textarea {
            background: #eff0f7;
            border-radius: 16px;
            padding: 15px 18px;
            margin-top: 15px;
            border: none;
            color: #6e7191;
        }

        .upload-image-preview {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            padding: 0;
            background: #eff0f6;

            img {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                border: 10px solid #eff0f6;
                object-fit: cover;
            }
        }
    }

    .steps .current-info,
    .wizard>.steps .number {
        display: none;
    }

    .steps {
        ul {
            content: "";
            width: 30%;
            height: 4px;
            background: #eff0f7;
            border-radius: 8px;
            position: absolute;
            left: 50%;
            top: 42.5px;
            transform: translateX(-50%);

            &:before {
                position: absolute;
                font-size: 20px;
                bottom: 20px;
                left: 29.4%;
                transition: all 0.5s ease;
            }

            &:after {
                content: "";
                width: 10.33%;
                height: 4px;
                background: linear-gradient(114.44deg, #7b4dff 0%, #f042ff 100%);
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.5s ease;
            }

            &.step-2 {
                &:before {
                    left: 20.5%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 24.67%;
                    transition: all 0.5s ease;
                }
            }

            &.step-3 {
                &:before {
                    left: 30%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 34%;
                    transition: all 0.5s ease;
                }
            }

            &.step-4 {
                &:before {
                    left: 40%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 44%;
                    transition: all 0.5s ease;
                }
            }

            &.step-5 {
                &:before {
                    left: 50%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 54%;
                    transition: all 0.5s ease;
                }
            }

            &.step-6 {
                &:before {
                    left: 60%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 64%;
                    transition: all 0.5s ease;
                }
            }

            &.step-7 {
                &:before {
                    left: 70%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 74%;
                    transition: all 0.5s ease;
                }
            }

            &.step-8 {
                &:before {
                    left: 80%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 84%;
                    transition: all 0.5s ease;
                }
            }

            &.step-9 {
                &:before {
                    left: 94%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 96%;
                    transition: all 0.5s ease;
                }
            }

            &.step-10 {
                &:before {
                    left: 100%;
                    transition: all 0.5s ease;
                }

                &:after {
                    width: 100%;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    // height range
    #height-range {
        margin: 50px 0 40px 0;
    }

    .noUi-target {
        background: #d9dbe9;
        height: 12px;
        border-radius: 40px;
        border: none;
    }

    .noUi-connect {
        background: #e80f6d;
        border-radius: 40px;
    }

    .noUi-connects {
        border-radius: 40px;
    }

    .noUi-horizontal .noUi-handle {
        border-radius: 50px;
        width: 25px;
        height: 25px;
        right: -17px;
        top: -7px;
        background: #f7f7fc;
        border: 2px solid #e80f6d;
        box-sizing: border-box;
        cursor: pointer;
    }

    .noUi-handle:after,
    .noUi-handle:before {
        display: none;
    }

    .noUi-marker-horizontal.noUi-marker-large {
        height: 0;
    }

    .noUi-horizontal .noUi-tooltip {
        background: #fadce9;
        width: 60px;
        border-radius: 16px;
        padding: 6px 16px;
        margin-bottom: 6px;
        border: none;
        font-family: $secondary-font-normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        color: #ba0c57;
    }

    .noUi-pips-horizontal {
        padding: 4px 0;
        font-family: $secondary-font-normal;
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0.4px;
        color: #14142b;
    }

    // tag cloud
    .tc-wrapper {
        display: block;
        height: 100%;
    }

    .tc-tag {
        display: inline-block;
        margin: 0 10px 10px 0;
        cursor: pointer;
        min-width: 126px;
        border: 2px solid #d9dbe9;
        box-sizing: border-box;
        border-radius: 40px;
        background: #ffffff;
        padding: 16px 24px;
        font-family: $secondary-font-normal;
        font-size: 14px;
        font-weight: 600;
        color: $primary-color;
        line-height: 28px;
        text-align: center;
    }

    .tc-selected {
        background: $primary-color;
        color: $white;
        border: 2px solid $primary-color;
    }
}

.actions {
    display: none;

    ul {
        display: flex;
        margin-top: 39px;
        justify-content: space-between;
    }

    li {
        a {
            padding: 0;
            border: none;
            display: inline-flex;
            height: 54px;
            width: 180px;
            letter-spacing: 1.3px;
            align-items: center;
            background: #e4bd37;
            font-family: "Muli-Bold";
            cursor: pointer;
            position: relative;
            padding-left: 34px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 27px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;

            &:before {
                content: "\f2ee";
                font-size: 18px;
                position: absolute;
                top: 17px;
                right: 34px;
                font-family: Material-Design-Iconic-Font;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
            }

            &:hover {
                background: #c9a630;

                &:before {
                    -webkit-animation-name: hvr-icon-wobble-horizontal;
                    animation-name: hvr-icon-wobble-horizontal;
                    -webkit-animation-duration: 1s;
                    animation-duration: 1s;
                    -webkit-animation-timing-function: ease-in-out;
                    animation-timing-function: ease-in-out;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                }
            }
        }

        &[aria-disabled="true"] {
            a {
                opacity: 0;
                transition: all 0.3s;
            }
        }

        &:first-child {
            a {
                width: 136px;
                background: #fff;
                color: #666;
                font-family: "Muli-Bold";
                padding-left: 57px;

                &:before {
                    content: "\f2ea";
                    left: 32px;
                    font-size: 18px;
                }

                &:hover {
                    background: #e5e5e5;
                }
            }
        }

        &:last-child {
            a {
                width: 154px;
            }
        }
    }
}

.form{
    width:50%;
    margin: 0 auto;

    @media(max-width:760px){
        width:90%;
        margin: 0 auto;
    }

    input {
        display:block;
        margin-bottom: 20px;
        padding: 18px 20px;
        border-radius: 16px;
        background-color: $color_4;
        border: none;
        width:60%;
        margin: 0 auto;
        margin-bottom:20px;

        @media(max-width:760px){
            width:90%;
            margin: 0 auto;
            margin-bottom:20px;
        }
    
    }

    button {
        padding: 15px 20px;
        background-color: $primary-color;
        color: $color_3;
        border: none;
        border-radius: 48px;
        width:60%;
        margin:0 auto;
        font-size:18px;

        &:hover{
            color: whitesmoke;
        }

        @media(max-width:760px){
            width:90%;
            margin: 0 auto;
        }
    
    }

}

.marginTop{
    margin-top:40px;
}

.formdiscription{
    width:50%;
    margin: 0 auto;
    text-align: center;
    margin-bottom:50px;

    .marginTop{
        margin-top:90px;
    }

    @media(max-width:760px){
        width:90%;
        margin: 0 auto;
        margin-bottom:50px;
    }

}

.backbutton{
    width:50%;
    margin: 0 auto;
    margin-bottom:50px;
    margin-top:80px;

    @media(max-width:760px){
        width:90%;
        margin: 0 auto;
        margin-bottom:50px;
        margin-top:80px;
    }



    .back-btn {
        width: 54px;
        height: 54px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-self: flex-start;
    
        .back-to-network {
            cursor: pointer;
    
            &:before {
                font-family: "Font Awesome 5 Free";
                vertical-align: middle;
                font-weight: 600;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                content: "\f053";
                color: $bg-dark;
                background: #e0e2f0;
                box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
                border-radius: 50%;
                width: 54px;
                height: 54px;
            }
        }
    }
    
}

@-webkit-keyframes hvr-icon-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px);
    }

    33.3% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }

    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
    }

    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px);
    }

    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes hvr-icon-wobble-horizontal {
    16.65% {
        -webkit-transform: translateX(6px);
        transform: translateX(6px);
    }

    33.3% {
        -webkit-transform: translateX(-5px);
        transform: translateX(-5px);
    }

    49.95% {
        -webkit-transform: translateX(4px);
        transform: translateX(4px);
    }

    66.6% {
        -webkit-transform: translateX(-2px);
        transform: translateX(-2px);
    }

    83.25% {
        -webkit-transform: translateX(1px);
        transform: translateX(1px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}