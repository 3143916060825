.aunty-signup-login {
    margin: 50px 40px 40px 30px;
    .promotional-left-panel {
        min-height: 89vh;
        background-color: $bg-dark-purple;
        border-radius: 40px;
        .image-container {
            padding: 100px 0 128px;
        }
        .text {
            color: $white;
            h3 {
                font-family: $primary-font-bold;
                font-size: 28px;
                line-height: 38px;
            }
            p {
                font-size: 20px;
                line-height: 32px;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
    .form-content-container {
        padding: 70px 0;
        .form-wrapper {
            padding: 30px 0;
            input {
                @include input;
                border-radius: 16px;
                padding: 20px 24px;
                margin-bottom: 20px;
            }
            select {
                @include input;
                border-radius: 16px;
                padding: 20px 24px;
                margin-bottom: 20px;
            }
            .select{
                :focus{
                    border:1px solid black;
                }
                
            }
            button {
                border-radius: 50px;
                height: 64px;
                width: 50px;
                color: $primary-color;
                background-color: transparent;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.75px;
                border: 1px solid $primary-color;
                &:hover {
                    color: $white;
                    background-color: $primary-color;
                    transition: all 1.0s ease;
                }
                &.btn.disabled, &.btn:disabled {
                    pointer-events: none;
                    opacity: .65;
                    background-color: $primary-color;
                    color: $white;
                }
                &.solid-btn {
                    background: $primary-color;
                    border-radius: 40px;
                    font-family: $primary-font-normal;
                    font-weight: 600;
                    width: auto;
                    padding-left: 25px;
                    padding-right: 25px;
                    color: $white;
                    &:before {
                        content: none;
                    }
                    &:hover {
                        color: $white;
                        background-color: $primary-color;
                        transition: all 1.0s ease;
                    }
                }
                &.transparent-btn {
                    background: $white;
                    border-radius: 40px;
                    font-family: $primary-font-normal;
                    font-weight: 600;
                    width: auto;
                    padding-left: 25px;
                    padding-right: 25px;
                    color: $primary-color;
                    &:before {
                        content: none;
                    }
                    &:hover {
                        color: $white;
                        background-color: $primary-color;
                        transition: all 1.0s ease;
                    }
                }
            }
        }
        .after-form-separator{
            position: relative;
            span{
                font-family: $primary-font-normal;
                color: #6E7191;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.4px;
                &:before, &:after {
                    content: ' ';
                    border-top: #E0E2F0 solid 1px;
                    width: 43%;
                    position: absolute;
                    top: 13px;
                }
                &:before{
                    left: 0;
                }
                &:after {
                    right: 0;
                }
            }
        }
        .login-button-options {
            margin-top: 30px;
            margin-bottom: 17px;
            padding-bottom: 10px;
            border-bottom: 1px solid #E0E2F0;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 20px;
                a {
                    font-family: $primary-font-normal;
                    background-color: #FFFFFF;
                    border-radius: 64px;
                    font-size: 16px;
                    line-height: 28px;
                    padding: 17px 100px;
                    letter-spacing: 0.4px;
                    &:hover {
                        text-decoration: none;
                    }
                    &.with-email {
                        background-color: $primary-color;
                        color: $white;
                    }
                    &.google-btn {
                        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
                        color: #6E7191;
                        &:focus {
                            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168) !important;
                        }
                    }
                    &.facebook-btn {
                        color: #1877F2;
                        border: 1px solid #1877F2;
                    }
                    &.twitter-btn {
                        color: #1E8EEE;
                        border: 1px solid #1E8EEE;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    svg{
                        position: relative;
                        top: -2px;
                        margin-right: 8px;
                    }
                }
            }
        }
        .links-wrapper {
            p {
                margin: 0;
                padding: 0;
                font-family: $primary-font-normal;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: 0.4px;
                color: #6E7191;
            }
        }
    }
}
.aunty-verify {
    input{
        background-color: #EFF0F7;
        border-radius: 6px;
        padding: 6px 18px;
        height: 54px;
        border: none;
        color: #6E7191;
        text-align: center;
        border: none;

        &:focus, .form-control:focus {
            color: #6E7191;
            background-color: #EFF0F7;
            border: 1px solid #d0d0d0 !important;
        }
    }
    
}