// font declaration
@font-face {
  font-family: 'DMSans-Bold';
  src: url('../fonts/DMSans-Bold.eot');
  src: url('../fonts/DMSans-Bold.woff') format('woff'), url('../fonts/DMSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'DMSans-Medium';
  src: url('../fonts/DMSans-Medium.eot');
  src: url('../fonts/DMSans-Medium.woff') format('woff'), url('../fonts/DMSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: 600;
}
@font-face {
  font-family: 'DMSans-Regular';
  src: url('../fonts/DMSans-Regular.eot');
  src: url('../fonts/DMSans-Regular.woff') format('woff'), url('../fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.eot');
  src: url('../fonts/Poppins-Bold.woff') format('woff'), url('../fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.eot');
  src: url('../fonts/Poppins-Regular.woff') format('woff'), url('../fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// primary font
$primary-font-normal      : 'DMSans-Regular';
$primary-font-medium      : 'DMSans-Medium';
$primary-font-bold        : 'DMSans-bold';
// secondary font
$secondary-font-normal    : 'Poppins-Regular';
$secondary-font-bold      : 'Poppins-bold';