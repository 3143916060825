.imageLoaderContainer {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg {
  width: 26px;
  height: 26px;
  padding: 4px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #e0e2f0;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 1px 2px 2px gainsboro;
  animation: pulse 4s linear infinite;
}

.svg1 {
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  padding: 4px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #e0e2f0;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 1px 2px 2px gainsboro;
  animation: pulse 4s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
