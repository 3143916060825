@mixin common-text-primary {
    font-family: $primary-font-normal;
    color: #6E7191;
}

@mixin heading-one($weight) {
    font-family: $primary-font-normal;
    font-size: 32px;
    line-height: 44px;
    color: #14142B;
    padding: 0;
    margin: 0;

    @if $weight == bold {
        line-height: 48px;
        font-family: $primary-font-bold;
        letter-spacing: 0.2px;
        color: #14142B;
    }
}

@mixin card-bg($bg-color) {
    background-color: $white;
    // color: $white;
    
    @if $bg-color == dark {
        background-color: $bg-dark;
        color: $white;
    }
}

@mixin icon-container($type) {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #FADCE9;
    margin-bottom: 14px;

    @if $type == dark {
        background-color: rgba(250, 220, 233, 0.1);
    }

    svg {
        width: 24px;
        height: 24px;
        margin: 22px 20px;
    }
}

@mixin card-link($type) {
    font-family: $secondary-font-normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.75px;
    border: 2px solid #D9DBE9;
    box-sizing: border-box;
    border-radius: 40px;
    margin-top: 14px;
    color: $primary-color;
    background-color: transparent;
    padding: 6px 32px;
    &:hover {
        background-color: #d9dbe973;
        transition: all 1.0s ease;
    }

    @if $type == dark {
        border: 2px solid #4E4B66;
        color: #FADCE9;
        &:hover {
            background-color: #fadce942;
            transition: all 1.0s ease;
        }
    }
}

@mixin input {
    background: #EFF0F7;
    border: none;
    color: #6E7191;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #A0A3BD;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #A0A3BD;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #A0A3BD;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #A0A3BD;
    }
}

@mixin select {
    background: #EFF0F7;
    border: none;
    color: #6E7191;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #A0A3BD;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #A0A3BD;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #A0A3BD;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #A0A3BD;
    }
}

@mixin textarea {
    background: #EFF0F7;
    border: none;
    color: #6E7191;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #A0A3BD;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #A0A3BD;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #A0A3BD;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #A0A3BD;
    }
}

@mixin round-image ($size) {
    border-radius: 50%;
    background-position: 70% 50%;
    background-repeat: no-repeat;
    background-color: #f7f7f7;
    background-size: 130%;
    object-fit: cover;

    @if $size == 32 {
        width: 32px;
        height: 32px;
        border: 2px solid #E0E2F0;
    }
    @if $size == 40 {
        width: 40px;
        height: 40px;
        border: 1px solid #E0E2F0;
    }
    @if $size == 48 {
        width: 48px;
        height: 48px;
        border: 2px solid #E0E2F0;
    }
    @if $size == 50 {
        width: 50px;
        height: 50px;
        margin-right: 12px;
    }
    @if $size == 56 {
        width: 56px;
        height: 56px;
    }
    @if $size == 120 {
        width: 120px;
        height: 120px;
        border: 2px solid #E0E2F0;
    }
    
}