* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section.pricing-modal {
  width: 100%;
  border-radius: 22px;
}
div.pricing-modal-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-modal-top p {
  font-weight: 500;
  font-size: 20px;
}
.pricing-modal-top .cancel-button {
  width: 13px;
  height: 13px;
}

section.pricing-cards-container {
  background-color: #f2f3f7;
  margin-top: 20px;
  border-radius: 22px;
  padding-top: 2rem;
  display: flex;
  gap: 35px;
  justify-content: center;
  align-items: center;
}

.pricing-card {
  background-color: #fcfcfc;
  padding: 30px;
  width: 33%;
  /* height: 426px; */
  border-radius: 22px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
}
.pricing-card h2 {
  height: 48px;
  font-size: 32px;
  font-weight: 700;
  color: #14142b;
}
.pricing-card h2 > span {
  color: #14142b;
  font-weight: 400;
  font-size: 16px;
}
.pricing-card h3 {
  margin-top: 18px;
}
.pricing-card p {
  margin-top: 8px;
  margin-bottom: 20px;
  color: #4e4b66;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.pricing-card ul {
  list-style: none;
  padding-left: 0 !important;
  margin-top: 1rem;
}
.pricing-card ul li {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  color: #6e7191;
}
.pricing-card img.checkmark-img {
  background: rgba(95, 46, 234, 0.1);
  padding: 3px;
  border-radius: 50%;
  margin-right: 5px;
}
.pricing-card button.choose-plan {
  color: #e80f6d;
  background: transparent;
  padding: 10px 20px;
  border-radius: 40px;
  width: 100%;
  border: 2px solid #d9dbe9;
  margin-top: 40px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.pricing-card.plan-2 {
  background-color: #5f2eea;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
}
.pricing-card.plan-2 h2,
.pricing-card.plan-2 h3,
.pricing-card.plan-2 p,
.pricing-card.plan-2 span {
  color: #fcfcfc !important;
}
.pricing-card.plan-2 img.checkmark-img {
  background: rgba(255, 255, 255, 0.2);
}
.pricing-card.plan-2 button {
  background-color: #ffffff;
  color: #e80f6d;
}

@media (max-width: 765px) {
  section.pricing-cards-container {
    flex-wrap: wrap;
  }
  .pricing-card {
    width: 90%;
  }
}
